import moment from 'moment';
import { utils, writeFile } from 'xlsx';
/* eslint-disable react/jsx-props-no-spreading */
import * as d3 from 'd3';
import { isNaN } from 'lodash';
import { Button, Empty, Slider } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo,  useState } from 'react';
import styled from 'styled-components';
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Customized,
    ResponsiveContainer,
    // Area,
} from 'recharts';
import { useSelector } from 'react-redux';
// import { PlanningContext } from '../../contexts/needs/PlanningContext';
import { generateString } from '../../helpers/string-helper';
/* eslint-disable */
import { dateToTimestamp, dateToUTCDate } from '../../helpers/planning';
import { NEED_CHART_DATA, NEED_CHART_TYPE, NEED_HISTOGRAM_TYPE, SLICE_DATA_TYPE } from '../../constants/Needs';
import { xAxisDateFormat, xAxisDateScaleFunction } from '../charts/CustomHistogramDateXaxis';
import CustomizedDot from '../charts/customized/CustomizedDot';
import CustomizedActiveDot from '../charts/customized/CustomizedActiveDot';
import CustomizedLines from '../charts/customized/CustomizedLines';
import CustomDateRectangles from '../charts/customized/CustomDateRectangles';
import { TooltipContent, TooltipLabelDate } from '../charts/customized/CustomizedTooltip';
import CustomizedBar from '../charts/customized/CustomizedBar';
import { notificationError } from '../../helpers/notification';
import { selectChartData, selectChartOptions, selectNeedList } from '../../redux/slices/chart/need-chart.slice';
import { selectScale } from '../../redux/slices/slicing/activity-calendar.slice';
import { getDurationUnit } from '../plannings/gantt_utils';

const StyledContainer = styled.div`
    .recharts-curve.recharts-line-curve {
        display: none;
    }
`;

const SliderContainer = styled.div`
    .ant-slider-dot {
        border: none;
        background-color: unset;
    }
`;

const SCALE_HEIGHT = 35;
const SCALE_FONT_SIZE = 11;
const YAXIS_WIDTH = 80;
const MIN_BAR_SIZE = 30;
const BRUSH_STEP = 1;
const MAX_CHART_DATA = 100;

const SliderMarksGenerator = ({ label, style }) => (
    <label
        className="text-primary"
        style={{ position: 'absolute', top: '30px', width: '100px', textAlign: 'center', right: '-50px', ...style }}
    >
        {label}
    </label>
);

const HistogramTabHistogram = () => {
    let showDefaultTooltip;
    let activeDotDataKey;
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const needTranslation = useTranslation('translation', { keyPrefix: 'needs' }).t;
    const histogramTranslation = useTranslation('translation', { keyPrefix: 'histogram' }).t;
    const [refreshChart, setRefreshChart] = useState(0);
    const [brushStartIndex, setBrushStartIndex] = useState(0);
    const [brushEndIndex, setBrushEndIndex] = useState(0);
    // const [showDefaultTooltip, setShowDefaultTooltip] = useState(true);
    const scale = useSelector(selectScale);
    const chartData = useSelector(selectChartData);
    const chartOptions = useSelector(selectChartOptions);
    const needList = useSelector(selectNeedList);
    const isHourScale = scale === 'hours';

    useEffect(() => {
        if (chartData.length > 0) {
            console.log('🚀 ~ file: HistogramTabHistogram.jsx:88 ~ useEffect ~ chartData:', chartData);
            const chartWidth = document.getElementById('gantt-histogram-container').offsetWidth - YAXIS_WIDTH;
            const dataCountByChartWidth = Math.floor(chartWidth / MIN_BAR_SIZE);
            console.log(
                '🚀 ~ file: HistogramTabHistogram.jsx:60 ~ useEffect ~ dataCountByChartWidth:',
                dataCountByChartWidth
            );
            if (chartData.length > dataCountByChartWidth) {
                setBrushStartIndex(0);
                setBrushEndIndex(dataCountByChartWidth - 1);
            } else {
                setBrushStartIndex(0);
                setBrushEndIndex(chartData.length - 1);
            }
        }
    }, [chartData]);

    const cumulativeChartData = useMemo(() => {
        const result = [];
        chartData.forEach((data, index) => {
            const cumulativeData = { ...data };
            if (index > 0) {
                Object.keys(data).forEach((key) => {
                    if (key !== 'date') {
                        cumulativeData[key] += result[index - 1][key];
                    }
                });
            }
            result.push(cumulativeData);
        });
        return result;
    }, [chartData]);

    const displayedData = useMemo(
        () =>
            chartOptions?.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData.slice(brushStartIndex, brushEndIndex)
                : cumulativeChartData.slice(brushStartIndex, brushEndIndex),
        [brushStartIndex, brushEndIndex, chartOptions?.dataType, cumulativeChartData]
    );

    const getXAxisArgsForTimeBasedGraph = useCallback(
        (scaleFunction, isSubScale) => {
            if (chartData.length > 0 && scale) {
                const timeUnit = isSubScale
                    ? xAxisDateFormat(generalTranslation)[scale].subScaleUnit
                    : xAxisDateFormat(generalTranslation)[scale].mainScaleUnit;
                    const startTime = isHourScale
                    ? dateToUTCDate(moment.utc(chartData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf())
                    : moment.utc(chartData[0].date).startOf(timeUnit).valueOf();
                const endTime = isHourScale
                    ? dateToUTCDate(
                          moment
                              .utc(chartData[chartData.length - 1].date)
                              .endOf(timeUnit)
                              .valueOf()
                      )
                    : moment
                          .utc(chartData[chartData.length - 1].date)
                          .endOf(timeUnit)
                          .valueOf();
                const timeScale = d3.scaleTime().domain([startTime, endTime]);
                const dateFormat = isSubScale ? xAxisDateFormat(generalTranslation)[scale].subScale : xAxisDateFormat(generalTranslation)[scale].mainScale;
                return {
                    tickFormatter: (date) => moment.utc(date).format(dateFormat),
                    ticks: timeScale.ticks(scaleFunction()).map((date) => dateToTimestamp(date)),
                };
            }
            return {};
        },
        [chartData, scale]
    );

    const mainAxisConfig = useMemo(() => {
        if (chartData.length > 0) {
            return getXAxisArgsForTimeBasedGraph(xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.mainScaleUnit], false);
        }
        return {};
    }, [chartData]);

    

    const handleActiveDotMouseHover = (lineDataKey) => {
        showDefaultTooltip = false;
        activeDotDataKey = lineDataKey;
    };
    const handleActiveDotMouseLeave = () => {
        showDefaultTooltip = true;
        activeDotDataKey = null;
    };

    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && activeDotDataKey && !showDefaultTooltip) {
            const activeDotPayload = payload.find((i) => i.name === activeDotDataKey);
            return (
                <div className="bg-white border p-2">
                    <TooltipLabelDate label={label} />
                    <TooltipContent payload={activeDotPayload} />
                </div>
            );
        }
        return (
            <div className="bg-white border p-2">
                <TooltipLabelDate label={label} />
                {(payload || []).map((currentPayload) => {
                    const payloadToShow = {...currentPayload};
                    if (!isNaN(currentPayload?.value)) {
                        payloadToShow.value = Number(currentPayload?.value).toFixed(2);
                        payloadToShow.realValue = +Number(currentPayload?.value).toFixed(2);
                    } else {
                        payloadToShow.realValue = 0;
                    }
                    if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
                        payloadToShow.value = ''.concat(payloadToShow.value).concat('/').concat(getDurationUnit(chartOptions.effortTimeUnitId).name);
                    }
                    return <TooltipContent key={generateString(5)} payload={payloadToShow} />;
                })}
            </div>
        );
    };

    const computeScaleDates = (data, tickFunction, scaleUnit) => {
        if (data.length > 0) {
            const currentData = data;
            console.log('🚀 ~ file: HistogramTabHistogram.jsx:213 ~ computeScaleDates ~ currentData:', currentData);
            const timeUnit = xAxisDateFormat(generalTranslation)[scale][scaleUnit];
            const startTime =
                timeUnit === 'hours'
                    ? moment.utc(currentData[0].date).startOf(timeUnit).subtract(1, timeUnit).valueOf()
                    : moment.utc(currentData[0].date).valueOf();
            const endTime = moment
                .utc(currentData[currentData.length - 1].date)
                .endOf(xAxisDateFormat(generalTranslation)[scale].mainScaleUnit)
                .valueOf();
            const domain = [startTime, endTime];
            const timeScale = d3.scaleUtc().domain(domain);
            const dates = [currentData[0].date];
            dates.push(...timeScale.ticks(tickFunction()).map((date) => moment.utc(date).startOf('minute').valueOf()));
            dates.push(
                moment.utc(currentData[currentData.length - 1].date)
                    .endOf(xAxisDateFormat(generalTranslation)[scale].mainScaleUnit)
                    .valueOf()
            );
            console.log('🚀 ~ file: HistogramTabHistogram.jsx:232 ~ computeScaleDates ~ dates:', dates);
            return dates;
        }

        return [];
    };

    const mainScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.mainScaleUnit],
                'mainScaleUnit'
            ),
        [displayedData, refreshChart]
    );

    const subScaleDates = useMemo(
        () =>
            computeScaleDates(
                displayedData,
                xAxisDateScaleFunction[xAxisDateFormat(generalTranslation)[scale]?.subScaleUnit],
                'subScaleUnit'
            ),
        [displayedData, refreshChart]
    );

    const getTimeScaleByDomainAndRange = (domain, range) => {
        const timeScale = d3.scaleUtc().domain(domain).range(range);
        return timeScale;
    };

    const computeScaleCoordinates = (dates, scaleType) => {
        if (dates.length > 0) {
            const dateFormat = xAxisDateFormat(generalTranslation)[scale][scaleType];
            let initialX = YAXIS_WIDTH;
            const xAxisWidth = document.getElementById('gantt-histogram-container').offsetWidth - YAXIS_WIDTH;
            
            // Create time scale with proper domain and range
            const timeScale = getTimeScaleByDomainAndRange([dates[0], dates[dates.length - 1]], [0, xAxisWidth]);
            console.log('🚀 ~ file: HistogramTabHistogram.jsx:272 ~ computeScaleCoordinates ~ timeScale:', timeScale);
            // Calculate rectangles for scale display
            const rectCoordinates = dates.flatMap((date, index) => {
                if (index > 0) {
                    const lastDatePosition = timeScale(dates[index - 1]);
                    const datePosition = timeScale(date);
                    const widthRect = datePosition - lastDatePosition;
                    
                    // Use isHourScale for hour-specific checks
                    if ((isHourScale && widthRect <= 0) || widthRect < 0) {
                        return [];
                    }
                    
                    const res = {
                        widthRect,
                        heightRect: SCALE_HEIGHT,
                        text: isHourScale 
                            ? moment.utc(date).format(dateFormat)
                            : moment.utc(dates[index - 1]).format(dateFormat),
                        x: initialX,
                        isCentered: true,
                        timestamp: isHourScale ? dates[index] : dates[index -1], // Use current date as timestamp, not previous
                    };
                    initialX += widthRect;
                    return res;
                }
                return [];
            });
            console.log('🚀 ~ file: HistogramTabHistogram.jsx:299 ~ computeScaleCoordinates ~ rectCoordinates:', rectCoordinates);
            return rectCoordinates;
        }
        return [];
    };

    /**
     * Converts an array of scale coordinates to a lookup object for O(1) access
     * @param {Array} coordinates - Array of scale coordinate objects
     * @param {boolean} shiftForLineChart - Whether to shift associations for line charts
     * @returns {Object} Object with timestamps as keys for fast lookup
     */
    const createCoordinatesLookup = (coordinates, shiftForLineChart = false) => {
        if (!coordinates || !coordinates.length) return {};
        
        const coordinatesLookup = coordinates.reduce((lookup, coord, index) => {
            // For line charts, shift the association one position back
            if (shiftForLineChart && index > 0) {
                /* eslint-disable-next-line */
                lookup[coordinates[index - 1].timestamp] = {
                    ...coord,
                    index
                };
            } else {
                // Use the timestamp as key for direct access
                /* eslint-disable-next-line */
                lookup[coord.timestamp] = {
                    ...coord,
                    index
                };
            }
            return lookup;
        }, {});
        
        // Handle the last point correctly to ensure the line ends at zero
        if (shiftForLineChart && coordinates.length > 0) {
            const lastCoord = coordinates[coordinates.length - 1];
            // Create a virtual end point for the last segment
            coordinatesLookup[lastCoord.timestamp] = {
                ...lastCoord,
                index: coordinates.length,
                // Ensure the end point is properly positioned at the far right
                x: lastCoord.x + lastCoord.widthRect,
                isEndPoint: true
            };
        }
        
        return coordinatesLookup;
    };


    /**
     * Creates a mapping between data dates and scale coordinates
     * @param {Array} displayedData - The data points to display
     * @param {Array} coordinates - The scale coordinates
     * @returns {Object} Lookup object mapping data dates to correct scale positions
     */
    const createDataToScaleMapping = (displayedData, coordinates) => {
        if (!displayedData.length || !coordinates.length) return {};
        
        const mapping = {};
        
        // Create a structure that's easier to search
        const dateRanges = coordinates.map((coord, index) => {
            // For each coordinate, define its date range
            const startDate = index > 0 ? coordinates[index - 1].timestamp : null;
            const endDate = coord.timestamp;
            return { coord, startDate, endDate };
        });

        // Map each data point to the correct coordinate
        displayedData.forEach((dataPoint) => {
            const dataDate = moment.utc(dataPoint.date).valueOf();
            
            // Find which range contains this date
            for (let i = 0; i < dateRanges.length; i++) {
                const range = dateRanges[i];
                
                // Special case for first range
                if (i === 0 && dataDate <= range.endDate) {
                    mapping[dataPoint.date] = range.coord;
                    break;
                }
                
                // For all other ranges, check if data falls within this range
                if (range.startDate && range.endDate && 
                    dataDate > range.startDate && dataDate <= range.endDate) {
                    mapping[dataPoint.date] = range.coord;
                    break;
                }
            }
        });
        
        return mapping;
    };

    // Update mainScaleCoordinates to create proper data-to-scale mapping
    const mainScaleCoordinates = useMemo(() => {
        const coordinates = computeScaleCoordinates(mainScaleDates, 'mainScale');
        
        // Create the lookup object with shift for line charts
        const isLineChart = chartOptions?.chartType === NEED_CHART_TYPE.LINE;
        const lookup = createCoordinatesLookup(coordinates, isLineChart);
        
        // // Create mapping between data points and scale coordinates
        // const dataMapping = createDataToScaleMapping(displayedData, coordinates);
        
        // // Merge the mappings into the lookup
        // Object.keys(dataMapping).forEach((date) => {
        //     lookup[date] = dataMapping[date];
        // });
        console.log('🚀 ~ file: HistogramTabHistogram.jsx:402 ~ mainScaleCoordinates ~ lookup:', lookup);
        
        return {
            array: coordinates || [],
            lookup
        };
    }, [mainScaleDates, displayedData, chartOptions?.chartType]);

    const subScaleCoordinates = useMemo(() => computeScaleCoordinates(subScaleDates, 'subScale'), [subScaleDates]);

    

    const handleBrushIndexChange = ([startIndex, endIndex]) => {
        // prevent max visible data
        if (endIndex - startIndex > MAX_CHART_DATA) {
            setBrushStartIndex(startIndex);
            setBrushEndIndex(startIndex + MAX_CHART_DATA - 1);
        } else {
            setBrushStartIndex(startIndex);
            setBrushEndIndex(endIndex);
        }
    };

    const brushTooltipFormatter = (index) => {
        let indexToCheck = index;
        if (index > chartData.length - 1) {
            indexToCheck = chartData.length - 1;
        } else if (index === brushEndIndex && index > 0) {
            indexToCheck -= 1;
        }
        const dateValue = chartData[indexToCheck].date;
        return moment.utc(dateValue).format(window.dateStringFormat);
    };

    const marks = useMemo(() => {
        const currentMarks = {};
        if (chartData.length > 0) {
            currentMarks[0] = (
                <SliderMarksGenerator label={moment.utc(chartData[0].date).format(window.dateStringFormat)} />
            );
            if (brushStartIndex !== 0) {
                currentMarks[brushStartIndex] = (
                    <SliderMarksGenerator
                        label={moment.utc(chartData[brushStartIndex].date).format(window.dateStringFormat)}
                    />
                );
            }
            if (brushEndIndex < chartData.length - 1 && brushEndIndex > 0) {
                currentMarks[brushEndIndex] = (
                    <SliderMarksGenerator
                        label={moment.utc(chartData[brushEndIndex - 1].date).format(window.dateStringFormat)}
                    />
                );
            }
            currentMarks[Math.ceil((chartData.length - 1) / BRUSH_STEP) * BRUSH_STEP] = (
                <SliderMarksGenerator
                    label={moment.utc(chartData[chartData.length - 1].date).format(window.dateStringFormat)}
                    style={{ width: 'auto', right: '0px' }}
                />
            );
        }
        return currentMarks;
    }, [brushStartIndex, brushEndIndex]);

    const labelValue = useMemo(() => {
        if (chartOptions?.sliceDataType === SLICE_DATA_TYPE.EFFORT) {
            const timeUnit = getDurationUnit(chartOptions.effortTimeUnitId);
            return `${histogramTranslation('shown_data_effort')}: ${needTranslation('needs')} / ${timeUnit.name}`
        }
        return '';
    }, [chartOptions])

    const exportData = useCallback(() => {
        try {
            const sourceData = (chartOptions && chartOptions.dataType === NEED_CHART_DATA.INSTANTANEOUS
                ? chartData
                : cumulativeChartData)
            // create a new workbook
            const wb = utils.book_new();
                // Header
            const sheetHeader = [needTranslation('needs')]
            sourceData.forEach((entry) => {
                sheetHeader.push(new Date (dateToUTCDate(entry.date)));
            });
            // Histogram Data
            const sheetData = needList.filter((i) => i.selected).reduce((old, current) => {
                const lineData = old;
                lineData[current.name] = [current.name];
                return lineData;
            }, {});
            const needNames = Object.keys(sheetData); // used for accessing data from chart data
            sourceData.forEach((entry) => {
                needNames.forEach((need) => {
                    sheetData[need].push(entry[need]);
                })
            });
            const dataToExport = [sheetHeader];
            dataToExport.push(...Object.values(sheetData));
            // convert the data to a worksheet
            const taskSheetData = utils.aoa_to_sheet(dataToExport, {UTC: true});
            taskSheetData ['!cols'] = sheetHeader.map(() => ({wch: 20}));
            // add the worksheet to the workbook
            utils.book_append_sheet(wb, taskSheetData, 'Histogram Data');
            // save the workbook as a file
            writeFile(wb, `Histogram.xlsx`);
        } catch (error) {
            notificationError('', generalTranslation('generic_error'));
        }
    }, [chartData, cumulativeChartData, chartOptions, needList]);

    return chartOptions && displayedData.length > 0 ? (
        <StyledContainer className="flex-col w-full h-full">

            <div className="w-full" style={{ height: `calc(100% - ${`${2 * SCALE_HEIGHT}px`})` }}>
                <ResponsiveContainer height="100%" width="100%" onResize={() => setRefreshChart((old) => old + 1)}>
                    <ComposedChart
                        width={1000}
                        barCategoryGap={0}
                        barGap={0}
                        margin={{ bottom: 2 * SCALE_HEIGHT }}
                        data={
                            displayedData
                        }
                    >
                        <CartesianGrid 
                            stroke="#828282" 
                            id="gantt-histogram-grid" 
                            verticalCoordinatesGenerator={() => {
                                // Generate grid lines that align with scale points
                                return mainScaleCoordinates.array.map(c => c.x);
                            }}
                        />
                        <YAxis  label={{value: labelValue, angle: -90, fontSize: 16, position: 'outsideBottomLeft'}} fontSize={SCALE_FONT_SIZE} width={YAXIS_WIDTH} domain={['auto', 'dataMax + 10']} />
                        <XAxis
                            dataKey="date"
                            scale={`${chartOptions.chartType === NEED_CHART_TYPE.BAR ? 'band' : null}`}
                            interval={0}
                            fontSize={SCALE_FONT_SIZE}
                            {...mainAxisConfig}
                            height={SCALE_HEIGHT}
                            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                            tickSize={SCALE_HEIGHT}
                            hide
                        />

                        <Tooltip cursor={false} isAnimationActive={false} content={CustomTooltip} offset={20} />
                        {chartOptions.chartType === NEED_CHART_TYPE.BAR &&
                            needList
                                .filter((i) => i.selected)
                                .map((need, index, filteredList) => (
                                    <Bar
                                        key={generateString(2)}
                                        dataKey={need.name}
                                        stackId={
                                            chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED ? 'bar' : null
                                        }
                                        name={need.name}
                                        isAnimationActive={false}
                                        fill={need.color}
                                        shape={
                                            <CustomizedBar
                                                scaleCoordinates={mainScaleCoordinates.lookup}
                                                dataOrder={index}
                                                dataCount={filteredList.length}
                                                isStacked={chartOptions.histogramType === NEED_HISTOGRAM_TYPE.STACKED}
                                                data={displayedData} // Pass data directly to ensure rendering
                                            />
                                        }
                                    />
                                ))}
                        {chartOptions.chartType === NEED_CHART_TYPE.LINE &&
                            needList
                                .filter((i) => i.selected)
                                .map((need) => (
                                    <Line
                                        key={generateString(2)}
                                        activeDot={<CustomizedActiveDot
                                            scaleCoordinates={mainScaleCoordinates.lookup}
                                            handleActiveDotMouseHover={() => handleActiveDotMouseHover()}
                                            handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                        />}
                                        dot={
                                            <CustomizedDot
                                                scaleCoordinates={mainScaleCoordinates.lookup}
                                                handleActiveDotMouseLeave={() => handleActiveDotMouseLeave()}
                                            />
                                        }
                                        onMouseLeave={() => handleActiveDotMouseLeave(need.name)}
                                        dataKey={need.name}
                                        stroke={need.color}
                                        strokeWidth={0}
                                        name={need.name}
                                        isAnimationActive={false}
                                    />
                                ))}
                                {chartOptions.chartType === NEED_CHART_TYPE.LINE && (
                            <Customized component={<CustomizedLines coordinates={mainScaleCoordinates.lookup} />} />
                        )}
                        <Customized component={<CustomDateRectangles coordinates={subScaleCoordinates} />} />
                        <Customized
                            component={<CustomDateRectangles isMainScale coordinates={mainScaleCoordinates.array} />}
                        />
                        {/* {showBrush && (
                        <Brush
                            dataKey="date"
                            startIndex={brushStartIndex}
                            endIndex={brushEndIndex}
                            onChange={handleBrushIndexChange}
                            gap={5}
                            tickFormatter={(val) => moment.utc(val).format(window.dateStringFormat)}
                        />
                    )} */}
                        {/* {showBrush && <Customized component={<CustomizedSlider />} />} */}
                    </ComposedChart>
                </ResponsiveContainer>
                <Button id='export-histogram-excel-button' size="small" className="hide absolute top-0" onClick={() => exportData()}/>
            </div>

            <SliderContainer className="w-full" style={{ height: `${SCALE_HEIGHT}px` }}>
                <Slider
                    className="m-0"
                    style={{ marginLeft: `${YAXIS_WIDTH}px`, marginRight: '5px' }}
                    range={{
                        draggableTrack: true,
                    }}
                    value={[brushStartIndex, brushEndIndex]}
                    onChange={handleBrushIndexChange}
                    marks={marks}
                    min={0}
                    tooltip={{ formatter: brushTooltipFormatter }}
                    max={Math.ceil((chartData.length - 1) / BRUSH_STEP) * BRUSH_STEP}
                    step={BRUSH_STEP}
                    railStyle={{ height: `${SCALE_HEIGHT}px`, background: '#eeeeee' }}
                    trackStyle={{ height: `${SCALE_HEIGHT}px`, background: '#555' }}
                    handleStyle={{
                        height: `${SCALE_HEIGHT}px`,
                        width: '5px',
                        borderRadius: 'unset',
                        margin: 0,
                        border: 'solid #000',
                        cursor: 'col-resize',
                    }}
                />
            </SliderContainer>
        </StyledContainer>
    ) : (
        <Empty />
    );
};

export default HistogramTabHistogram;
